<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      color="#fff"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
      width="100%"
      max-width="800px"
      max-height="50vh"
      transition="dialog-transition"
    >
      <v-card color="#fff">
        <div class="flow-setup">
          <div class="header" v-if="workflowStep !== 0">
            <div>
              <span class="title pl-1">{{ workflow.title }}</span>
              <v-btn
                icon
                @click="
                  editWorkflowName = true;
                  dialog = false;
                "
                color="#8f96a1"
              >
                <img
                  class="edit-icon"
                  src="https://embed.hypn.so/images/workflow/edit-pen.png"
                  alt=""
                />
              </v-btn>
            </div>
            <v-btn color="#FF0000" @click="closeWorkflowSDK" icon
              ><v-icon size="20">mdi-close</v-icon></v-btn
            >
          </div>
          <div class="flows">
            <v-skeleton-loader
              v-if="workflowStep == 0"
              type="card"
            ></v-skeleton-loader>

            <template v-if="workflowStep == 1">
              <div class="flow-setup__trigger">
                <trigger-workflow
                  :disabled="form !== ''"
                  :triggers="triggers"
                  v-model="workflow.trigger"
                  @next="workflowStep = 2"
                  isSDK
                  :apiKey="apiKey"
                />
              </div>

              <payment-trigger
                ref="paymentTrigger"
                v-if="isPaymentTrigger"
                v-model="workflow.payment"
                @next="workflowStep = 2"
                isSDK
                :apiKey="apiKey"
                :isVisable="isPaymentTrigger"
              />

              <form-trigger
                ref="formTrigger"
                v-if="isFormTrigger"
                isSDK
                :isEdit="form != ''"
                :apiKey="apiKey"
                @next="workflowStep = 2"
                v-model="workflow.form"
                :isVisable="isFormTrigger"
              />
            </template>

            <compose-workflow
              v-if="workflowStep == 2"
              ref="conditions"
              v-model="workflow.conditions"
              :isVisable="canShowConditions"
              :trigger="workflow.trigger"
              isSDK
              :apiKey="apiKey"
              @continue="
                workflow.composerIsReady = true;
                workflowStep = 3;
              "
              @back="workflowStep = 1"
              @inputs="workflow.inputs = $event"
              :trigger-data="
                isFormTrigger
                  ? workflow.form
                  : isPaymentTrigger
                  ? workflow.payment
                  : null
              "
              @selected-fields="workflow.fields = $event"
            />

            <execute-actions-workflow
              v-if="workflowStep == 3"
              ref="actions"
              isSDK
              :organizationId="organizationId.toString()"
              :apiKey="apiKey"
              :trigger="workflow.trigger"
              @publish="$refs.createWorflow.start()"
              @back="workflowStep = 2"
              :isVisable="canShowActions"
              v-model="workflow.actions"
              :exception="workflow.exception"
              @exception="workflow.exception = $event"
              :exceptions="workflow.exceptionSchema"
              @exception-schema="workflow.exceptionSchema = $event"
              :inputs="workflow.inputs"
            />
          </div>
        </div>
        <div class="powered-by-hyphen">
          <a
            href="https://tryhyphen.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span> powered by </span>
            <img
              :src="require('@/assets/icons/newLogo.svg')"
              alt="powered by hyphen"
            />
          </a>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editWorkflowName"
      max-width="650px"
      :persistent="!workflow.title"
      transition="dialog-transition"
    >
      <div class="edit-name">
        <div class="edit-name__header">
          <span> Workflow name </span>
          <v-spacer></v-spacer>
          <v-btn color="#FF0000" icon @click="closeWorkflowSDK"
            ><v-icon size="17">mdi-close</v-icon></v-btn
          >
        </div>

        <div class="edit-name__container">
          <v-text-field
            v-model="workflow.temp_title"
            name="name"
            label="Workflow name"
            placeholder="workflow name"
            hide-details="auto"
            color="primary"
            outlined
          ></v-text-field>
        </div>
        <div class="edit-name__footer">
          <v-btn
            large
            color="#19283D"
            dark
            @click="updateWorkflowName"
            elevation="0"
            :disabled="!workflow.temp_title"
          >
            <v-icon left>mdi-chevron-right</v-icon> Save
          </v-btn>
        </div>
      </div>
    </v-dialog>

    <create-workflow
      ref="createWorflow"
      isSDK
      :apiKey="apiKey"
      @success="dialog = false"
      @done="closeWorkflowSDK"
      :workflow="workflowPayload"
    />
  </div>
</template>

<script>
import TriggerWorkflow from "../components/workflow/trigger-workflow.vue";
import PaymentTrigger from "../components/workflow/trigger/payment-trigger.vue";
import FormTrigger from "../components/workflow/trigger/form-trigger.vue";
import ComposeWorkflow from "../components/workflow/compose/compose-workflow.vue";
import ExecuteActionsWorkflow from "../components/workflow/actions/execute-actions-workflow.vue";
import CreateWorkflow from "../components/workflow/create-workflow.vue";
export default {
  name: "WorkflowSdk",
  props: {
    triggers: {
      type: Array,
      default: () => ["Form", "Invoice", "Payment"],
    },
    apiKey: {
      type: String,
      default: "",
    },

    organizationId: {
      type: String,
      default: "",
    },

    name: {
      type: String,
      default: "",
    },

    form: {
      default: "",
    },
  },
  components: {
    PaymentTrigger,
    ComposeWorkflow,
    FormTrigger,
    ExecuteActionsWorkflow,
    TriggerWorkflow,
    CreateWorkflow,
  },

  data: () => ({
    dialog: true,
    publishDialog: false,
    publishDialogSucessful: false,
    showTriggers: true,
    editWorkflowName: false,
    scrollOptions: {
      duration: 500,
      offset: 0,
      easing: "easeInOutCubic",
      container: ".flows",
    },

    showAdvance: false,
    hypn_id: "",
    workflow: {
      title: "",
      temp_title: "",
      trigger: "",
      runs: 0,
      conditions: null,
      fields: null,
      payment: null,
      form: null,
      composerIsReady: false,
      inputs: null,
      actions: [],
      webhook: "",
      exception: true,
      exceptionSchema: null,
    },
    isPublishingWorkflow: false,
    workflowStep: 0,
  }),

  async mounted() {
    this.workflow.title = this.name;

    try {
      await this.$store.dispatch("workflow/getWorkFlowSDKData", {
        apiKey: this.apiKey,
        orgId: this.organizationId,
      });

      this.workflowStep = 1;

      if (this.form != "") {
        this.workflow.form = this.form;
        this.workflow.trigger = "form";
        this.workflowStep = 2;
      }

      if (this.workflow.title) {
        this.editWorkflowName = false;
        this.dialog = true;
      } else {
        this.editWorkflowName = true;
        this.dialog = false;
      }
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.log(JSON.stringify(error, null, 2));
      }
    }
  },
  methods: {
    updateWorkflowName() {
      this.workflow.title = this.workflow.temp_title;
      this.editWorkflowName = false;
    },

    closeWorkflowSDK() {
      this.$emit("destroy");
    },
  },

  watch: {
    // workflowStep: {
    //   immediate: true,
    //   handler(val) {
    //     if (val === 1 ) {
    //       if (this.workflow.title) {
    //         this.editWorkflowName = false;
    //         this.dialog = true;
    //       } else {
    //         this.editWorkflowName = true;
    //         this.dialog = false;
    //       }
    //     }
    //   },
    // },
    "workflow.trigger": {
      deep: true,
      handler() {
        if (this.form == "") {
          this.workflow.form = null;
          this.workflow.payment = null;
          this.workflow.conditions = null;
        }
      },
    },

    "workflow.title": {
      deep: true,
      immediate: true,
      handler() {
        this.workflow.temp_title = this.workflow.title;
      },
    },

    editWorkflowName() {
      if (!this.editWorkflowName) {
        this.dialog = true;
      }
    },

    workflowPayload: {
      deep: true,
      immediate: true,
      handler(val) {
        if (process.env.NODE_ENV === "development") {
          console.log(JSON.stringify(val, null, 2));
        }
      },
    },

    canShowConditions(val) {
      if (val) {
        this.workflowStep = 2;
      }
    },

    canShowActions(val) {
      if (val) {
        this.workflowStep = 3;
        // this.$nextTick(() => {
        //   this.$vuetify.goTo(this.$refs.actions, this.scrollOptions);
        // });
      }
    },

    // isPaymentTrigger(val) {
    //   if (val) {
    //     this.workflowStep = 2;
    //   }
    // },

    // isFormTrigger(val) {
    //   if (val) {
    //     this.workflowStep = 2;
    //   }
    // },

    // isInvoiceTrigger(val) {
    //   if (val) {
    //     this.workflowStep = 2;
    //   }
    // },
  },

  computed: {
    canShowConditions() {
      return (
        this.isInvoiceTrigger ||
        this.workflow.payment !== null ||
        this.workflow.form !== null
      );
    },

    canShowActions() {
      return this.workflow.composerIsReady;
    },

    isPaymentTrigger() {
      if (this.workflow.trigger && this.workflow.trigger == "payment") {
        return true;
      }
      return false;
    },

    isFormTrigger() {
      if (this.workflow.trigger && this.workflow.trigger == "form") {
        return true;
      }
      return false;
    },

    isInvoiceTrigger() {
      if (this.workflow.trigger && this.workflow.trigger == "invoice") {
        return true;
      }
      return false;
    },

    workflowPayload() {
      return {
        source: this.workflow.trigger,
        organization: this.organizationId,
        workflow_schema: {
          condition: this.workflow.conditions,
          actions: this.workflow.actions,
        },
        trigger_schema: this.workflow.fields,
        form: this.workflow.form,
        payment: this.workflow.payment,
        is_active: 1,
        workflow_title: this.workflow.title,
        exception_schema: this.workflow.exceptionSchema,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-name {
  padding: 30px;
  background-color: #f9fafa;

  &__header {
    display: flex;
    color: var(--primary-base);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
  }

  &__container {
    background-color: #fff;
    padding: 25px;
    border: 1px solid #d9dee1;
    border-radius: 4px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
.items-center {
  align-items: center;
}

.edit-icon {
  height: 21px;
}

.flow-setup {
  padding: 20px;
  background-color: #fff;

  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;

    .title {
      font-size: 30px;
      font-weight: 700;
      color: #8f96a1;
      text-transform: capitalize;
    }
  }

  &__trigger {
    width: 100%;
    padding: 30px;

    // mobile view
    @media (max-width: 768px) {
      padding: 0px;
    }
    background: #fff;
    box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
    border-radius: 6px;
  }
}

.animate-down {
  &enter {
    transform: translateY(-10px);
    opacity: 0;
  }
  &enter-active,
  &leave-active {
    transition: all 0.3s ease-out;
  }

  &leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.publish {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;
    }
  }

  &__content {
    background-color: #fefcf8;
    padding: 30px 50px;
    text-align: center;

    .msg {
      font-size: 16px;
      color: #757575;
      line-height: 24px;
      display: block;
      margin-bottom: 30px;
    }

    #add-to-draft {
      display: block;
      margin: 50px auto 0px auto;
      background: transparent;
      color: #d7a47b;
      cursor: pointer;
      font-size: 17px;
      border-bottom: 1px solid #d7a47b;
    }
  }
}

.publish-sucessful {
  border-radius: 8px;
  background-color: #fff;
  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;
    }
  }

  &__top {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 2px;
    padding: 20px;
    background-color: #f6f3ee;
    span {
      display: inline-block;
      margin-left: 10px;
      font-size: 21px;
      font-weight: bold;
      color: var(--primary-base);
    }
  }

  &__content {
    background-color: #f7f7f7;
    padding: 20px 50px;

    .msg {
      font-size: 16px;
      color: #757575;
      line-height: 24px;
      display: block;
      margin-bottom: 30px;
    }

    .cta {
      display: flex;
      justify-content: end;
    }
  }

  .api-cover {
    display: flex;
    align-items: center;
    border: 1px solid #19283d1a;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid rgba(25, 40, 61, 0.1);
    border-radius: 3px;
    margin: 10px 0px;

    &__req {
      height: 55px;
      padding: 0px 20px;
      background-color: #f4f5f6;
      color: #00233880;
      font-weight: bold;
      border-radius: 3px;
      font-family: "Inter";
    }

    &__url {
      padding: 0px 25px;
      flex: 1;
      color: #596a73;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.powered-by-hyphen {
  display: block;

  margin: 15px 0px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  span {
    font-size: 14px;
    color: #8f96a1;
    margin-right: 5px;
    text-decoration: none;
  }

  img {
    height: 15px;
    margin-left: 5px;
    opacity: 0.8;
  }
}
</style>
